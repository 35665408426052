import React from "react";
import Im from "immutable";

import { ErrorBoundary } from "traec-react/errors";

import Metric from "./reportMetricRow";
import Tree from "./reportMetricTree";
import Document from "./reportDocumentRow";

import { useReportContext } from "./context";
import { useProjectContext } from "../context";

/* Generic functions for recursing and rendering the nodes */

function ComponentNotFound({ path, type }) {
  return (
    <div className="row">
      <div className="col-sm-12">
        Component not found for node of type {type} at path {path}
      </div>
    </div>
  );
}

function Node(props) {
  let { node, showOnly, componentMap, indentLevel = 0 } = props;
  let _path = node.get("_path");
  let _type = node.get("_type");

  let { setInputValue } = useReportContext();

  // If we have showOnly passed as a prop then only render nodes that are in showOnly
  if (showOnly !== undefined && showOnly[_path] == undefined) {
    return null;
  }

  // Get the component to render this node with
  const COMPONENT_MAP = componentMap || {
    metricscore: Metric,
    tree: Tree,
    document: Document,
  };
  let Component = COMPONENT_MAP[_type];

  // If the node type does not match a valid type that we have a component for then render a message
  if (!Component) {
    return null;
    //return <ComponentNotFound path={_path} type={_type} />;
  }

  // Render the sub-component in an ErrorBoundary
  return (
    <ErrorBoundary
      title={null}
      className="row alert alert-warning pt-0 pb-0 mt-1 mb-0"
      msg={
        <span>
          Error rendering row for {_type} at {_path}
        </span>
      }
    >
      <Component
        {...props}
        handleInputChange={(e) => setInputValue(Im.Map({ _path, value: e.target.value }))}
        indentLevel={indentLevel + 1}
        path={_path}
        hidden={showOnly ? !showOnly[_path] : undefined}
      />
    </ErrorBoundary>
  );
}

function SortAndRender(props) {
  let { sortKey, nodes } = props;
  return nodes.sortBy((i) => i.get(sortKey) || i.get("name")).map((node, i) => <Node key={i} {...props} node={node} />);
}

export function SubNodes(props) {
  let { getNode, getChildren, commit, getMetricById } = useProjectContext();
  let { hide, path } = props;

  if (hide) return null;

  let sortKey = commit?.getIn(["meta_json", "sortKey"]);

  // Get the node
  let node = getNode(path);

  // Get the immediate children of this node
  let children = getChildren(node);

  // Get the additional children of this node
  let extraChildren = (node?.getIn(["meta_json", "additional_children"]) || Im.List())
    .map((i) => getMetricById(i))
    .map((i) =>
      i
        .getIn(["node", i.get("type")])
        .set("_path", i.get("path"))
        .set("_type", i.get("type"))
    );

  return (
    <>
      <SortAndRender {...props} nodes={children} sortKey={sortKey} parentPath={null} />
      <SortAndRender
        {...props}
        nodes={extraChildren}
        sortKey={sortKey}
        disableInputs={true}
        rowStyle={{ color: "grey", display: "none" }}
        parentPath={path}
      />
    </>
  );
}
