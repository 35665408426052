import React, { useState } from "react";
import Traec from "traec";
import { useParams } from "react-router-dom";

import { ErrorBoundary } from "traec-react/errors";
import { BSBtn, BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import { DropzoneButton } from "traec-react/utils/documentUpload/dropZone";

import { BreadCrumb } from "AppSrc/project/utils";
import CategoryRow from "./categoryRow";

import Swal from "sweetalert2";
import { alertSuccess } from "traec-react/utils/sweetalert";

import { Modal, useModal } from "storybook-dashboard/components/modal";
import ProjectContextWrapper from "AppSrc/project/context";
import { useFullIds } from "AppSrc/project/utils/hooks";
import { useProjectContext } from "../context";
import { mergeNodes } from "./node";

export const categoryNames = [
  "Air Quality",
  "Awards",
  "Biodiversity",
  "BREEAM",
  "Carbon",
  "Climate Change",
  "Community",
  "Community Wellbeing",
  "Diversity & Equality",
  "Economic Prosperity",
  "EDI",
  "Employee Engagement",
  "Employee Relations",
  "Employees",
  "EMS",
  "Equality, Diversity & Inclusion",
  "Ethics",
  "Future of Work",
  "Governance",
  "Governance & Safety",
  "Health & Safety",
  "Materials",
  "People Wellbeing",
  "Process & Productivity",
  "Procurement",
  "Quality",
  "Social Value",
  "Supply Chain",
  "Waste",
  "Water",
  //"Traction",
  //"Non-traction",
  "Project Attributes",
  "Other",
];

export const metricCounters = {
  row: 0,
};

const setCommitMeta = (commit, meta_json) => {
  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: commit.get("ref"),
    commitId: commit.get("uid"),
  });
  fetch.updateFetchParams({
    body: {
      meta_json,
    },
    postSuccessHook: (e) => location.reload(),
  });
  fetch.dispatch();
};

function SetReportLayout({ cref }) {
  let { commit } = useProjectContext();

  let current = commit.getIn(["meta_json", "report_layout"]) || "classic";
  let options = {
    tabbed: "Tabbed layout",
    classic: "Classic layout",
  };
  let _options = Object.entries(options).map(([key, value], i) => (
    <option key={i} value={key}>
      {value}
    </option>
  ));
  return (
    <form className="float-left">
      <select
        value={current}
        className="form-control form-control-sm"
        onChange={(e) => {
          e.preventDefault();
          setCommitMeta(commit, { report_layout: e.target.value });
        }}
      >
        {_options}
      </select>
    </form>
  );
}

export const SetCommitMeta = (props) => {
  let { commit, isRootRef } = useProjectContext();

  if (isRootRef) return null;

  let placeholder = commit.getIn(["meta_json", "submit_placeholder"]);
  let disableManualEntry = commit.getIn(["meta_json", "disable_manual_entry"]);

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: commit.get("ref"),
    commitId: commit.get("uid"),
  });
  fetch.updateFetchParams({
    preFetchHook: (body) => {
      if (body.disable_manual_entry === undefined) {
        body.disable_manual_entry = false;
      }
      return {
        meta_json: body,
      };
    },
    postSuccessHook: (e) => location.reload(),
  });

  return (
    <BaseFormConnected
      params={fetch.params}
      forceShowForm={true}
      submitBtnText={"Save Report Meta Information"}
      fields={{
        submit_placeholder: {
          label: "Set Report submit comment placeholder text",
          value: placeholder,
          class: "col-sm-12",
          placeholder: "Write a comment...",
          endRow: true,
        },
        disable_manual_entry: {
          label: "Disable Manual Entry for Calculated Metrics",
          value: disableManualEntry,
          inputType: "checkbox",
        },
      }}
    />
  );
};

function AddCategoryModal({ trackerId, crefId, commitId, fetches, categories }) {
  let { hideModal } = useModal();
  let fetch = new Traec.Fetch("tracker_node", "post", { trackerId, refId: crefId, commitId });

  let existingNames = new Set(categories.map((i) => i.get("name")));
  let allowedNames = categoryNames.filter((s) => !existingNames.has(s));

  let categoryFields = {
    name: {
      value: allowedNames ? allowedNames[0] : "", // Select the first name by default
      class: "col",
      inputType: "createableSelect",
      endRow: true,
      options: allowedNames.map((name, i) => ({ label: name, value: name })),
    },
  };

  fetch.updateFetchParams({
    preFetchHook: (data) => {
      return {
        type: "tree",
        node: {
          tree: data,
        },
      };
    },
    postSuccessHook: (newData) => {
      let { data, mutate } = fetches.nodes;
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Add an Issue">
      <BaseFormConnected params={fetch.params} fields={categoryFields} forceShowForm={true} hideUnderline={true} />
    </Modal>
  );
}

function SetupMenus(props) {
  let urlParams = useFullIds();

  return (
    <React.Fragment>
      <ErrorBoundary>
        <SetReportLayout />
      </ErrorBoundary>

      <BSBtnDropdown header={<span>Set up Metrics</span>} links={dropdownLinks({ ...props, ...urlParams })} />
      <div style={{ clear: "both" }} />
    </React.Fragment>
  );
}

function dropdownLinks(props) {
  let { _commitId } = useParams();
  let { commit, fetches, nodesByPath } = useProjectContext();
  let { setModal } = useModal();
  let { projectId, allowedCategoryNames, trackerId, commitId, urlParams } = props;

  let sortKey = commit?.getIn(["meta_json", "sortKey"]);
  let crefId = commit.get("ref");

  let reportIdPath = _commitId ? `/${_commitId}` : "";

  let thisItems = [
    {
      name: "Add Sustainability Issue",
      onClick: (e) =>
        setModal(<AddCategoryModal {...{ ...urlParams, ...props, fetches, nodesByPath, allowedCategoryNames }} />),
    },
    {},
    { name: "Add from Template", linkTo: `./metrics/add_from_template` },
    {
      name: "Add from CSV file",
      onClick: () => setModal(<FileSelectModal {...{ ...props, ...urlParams }} />),
    },
    {},
    {
      name: sortKey === "_path" ? "Sort alphabetically" : "Sort by path",
      onClick: () => {
        setCommitMeta(commit, { sortKey: sortKey === "_path" ? "name" : "_path" });
      },
    },
    {},
    {
      name: "View as Report",
      onClick: () => {
        let reportUrl = `/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/report${reportIdPath}`;
        window.location.assign(reportUrl);
      },
    },
    {},
    {
      name: "Export to CSV",
      onClick: () => {
        exportToCSV({ trackerId, commitId });
      },
    },
    {
      name: "Export to JSON",
      onClick: () => {
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = `/api-docs/auth/login/?next=/api/tracker/${trackerId}/commit/${commitId}/node/?format=json`;
        //link.download = `${trackerId.substring(0, 8)}_${commitId.substring(0, 8)}.json`;
        link.target = "_blank";
        link.rel = "noreferrer noopener";
        document.body.appendChild(link);
        link.click();
      },
    },
    {
      name: "Import from JSON",
      onClick: () => setModal(<ImportJsonModal {...props} />),
    },
  ];
  return thisItems;
}

function ImportJsonModal(props) {
  return (
    <Modal title="Import from JSON File">
      <div className="row text-center">
        <div className="container-fluid">
          <p className="text-center">Import from JSON file</p>
          <label className="btn btn-sm btn-secondary mt-2 py-0 pointer" style={{ height: "31px" }}>
            Select new File
            <input
              style={{ visibility: "hidden", height: "0px", width: "0px" }}
              type="file"
              onChange={(event) => importFromJSON({ target: event.target, trackerId, commitId })}
            />
          </label>
        </div>
      </div>
    </Modal>
  );
}

const postCSVFile = ({ selectedFiles, trackerId, crefId: refId }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });
  //
  let formData = new FormData();
  formData.append("fileobj", selectedFiles[0]);
  formData.append("type", "METRICS_FROM_CSV");
  formData.append("payload", JSON.stringify({ refId }));
  fetch.updateFetchParams({ body: formData });

  Swal.queue([
    {
      title: "Bulk-creation of metrics from CSV",
      confirmButtonText: "Start",
      html: "<p>The CSV file you have selected will try to be parsed and metrics created.</p>  <p>Existing metrics will not be affected.  This is an append-only operation.</p>  <p>This may take a while if you have many metrics.  Please be patient.</p>",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch
          .rawFetch()
          .then((response) => response.json())
          .then((data) => {
            Swal.insertQueueStep({
              type: "success",
              showConfirmButton: true,
              showCancelButton: false,
              title: "Metrics created successfully",
              html: "<p>The page will now reload</p>",
              confirmButtonText: "Done",
              onClose: () => {
                location.reload();
              },
            });
          })
          .catch((err) => {
            Swal.insertQueueStep({
              type: "error",
              title: "Error",
              text: "There was an error generating your report.  Please contact support if the problem persists.",
              onClose: () => {
                location.reload();
              },
            });
          });
      },
    },
  ]);
};

function SelectCSVFile(props) {
  let [selectedFiles, setSelectedFiles] = useState([]);

  let files = selectedFiles.map((file) => (
    <a key={file.name}>
      Upload {file.name}? ({(file.size / 1e6).toFixed(1)}Mb)
    </a>
  ));

  // Give a warning if the file is too large
  let confirmButton = null;
  if (selectedFiles.filter((file) => file.size / 1e6 > 500).length) {
    files = [
      <span key={0} className="alert-danger">
        Maximum allowed upload size is 500Mb
      </span>,
    ];
  } else {
    confirmButton = (
      <BSBtn
        text={"Upload"}
        onClick={(e) => postCSVFile({ ...props, selectedFiles })}
        extra_className="pl-1 pr-1 m-0 p-0"
        noFloatRight={true}
      />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="float-right">
          <DropzoneButton
            onDrop={(files) => setSelectedFiles(files)}
            extra_className="pl-1 pr-1 m-0 p-0"
            selectAreaText="Select file"
            confirmButton={confirmButton}
            selectedFiles={files}
            onCancelUpload={() => setSelectedFiles([])}
          />
        </div>
      </div>
    </div>
  );
}

const FileSelectModal = (props) => {
  return (
    <Modal title="Create from CSV file">
      <SelectCSVFile {...props} />
    </Modal>
  );
};

const postCommit = (e, params) => {
  e.preventDefault();
  let { trackerId, crefId, commitId } = params;
  let fetch = new Traec.Fetch("tracker_ref_commit", "post", { trackerId, refId: crefId, commitId });
  fetch.updateFetchParams({
    body: {
      comment: `Saved metric tree state ${new Date()}`,
      recurse: false,
    },
  });
  fetch.dispatch();
};

const importFromJSON = ({ target, trackerId, commitId }) => {
  let file = target.files[0];
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  if (file) {
    let formData = new FormData();
    formData.append("fileobj", file);
    formData.append("type", "IMPORT_FROM_JSON");
    formData.append("payload", JSON.stringify({ commitId }));
    fetch.updateFetchParams({ body: formData });

    fetch.updateFetchParams({
      body: formData,
      postSuccessHook: () => {
        alertSuccess({
          text: `JSON file uploaded`,
          onConfirm: () => {
            location.reload();
          },
        });
      },
    });

    fetch.dispatch();
  }
};

export const exportToCSV = ({ trackerId, commitId }) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  let formData = new FormData();
  formData.append("type", "METRICS_TO_CSV");
  formData.append("payload", JSON.stringify({ commitId }));
  fetch.updateFetchParams({ body: formData });

  Swal.queue([
    {
      title: "Export",
      confirmButtonText: "Generate CSV file",
      html: "<p>This may take a minute. Please be patient.</p>",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fetch
          .rawFetch()
          .then((response) => response.blob())
          .then((data) => {
            console.log("GOT REPORT DATA", data);
            let blobUrl = window.URL.createObjectURL(data);
            Swal.insertQueueStep({
              showConfirmButton: false,
              showCancelButton: false,
              title: "Download ready",
              html: `<p>Click here to download</p><a class="btn btn-primary" href="${blobUrl}" download="report_${commitId.substring(
                0,
                8
              )}_metrics.csv">Download</a>`,
            });
          })
          .catch((err) => {
            Swal.insertQueueStep({
              type: "error",
              title: "Error",
              text: "There was an error generating your report.  Please contact support if the problem persists.",
            });
          });
      },
    },
  ]);
};

export default function ProjectMetrics(props) {
  return (
    <ProjectContextWrapper>
      <MetricsWithCommitNodes {...props} />
    </ProjectContextWrapper>
  );
}

function MetricCategories({ categories }) {
  const items = categories.map((category, i) => <CategoryRow key={i} path={category.get("_path")} />);

  return <div className="container-fluid mt-3 mr-0 pr-0">{items}</div>;
}

function MetricsWithCommitNodes(props) {
  let { project, rootNode, getChildren, commit } = useProjectContext();

  let sortKey = commit?.getIn(["meta_json", "sortKey"]);

  const categories = getChildren(rootNode)
    .toList()
    .filter((i) => i.get("_type") === "tree")
    .sortBy((i) => i.get(sortKey) || i.get("name"));

  if (!project) return null;

  metricCounters.row = 0;
  return (
    <React.Fragment>
      <h3>Project Metrics</h3>
      <BreadCrumb />

      {/* Button for adding top-level tree objects */}
      <ErrorBoundary>
        <SetupMenus {...props} categories={categories} />
      </ErrorBoundary>

      {/* Render the categories and row */}
      <ErrorBoundary>
        <MetricCategories categories={categories} />
      </ErrorBoundary>

      <hr />
      <ErrorBoundary>
        <SetCommitMeta />
      </ErrorBoundary>
    </React.Fragment>
  );
}
