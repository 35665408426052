import { fetcher } from "storybook-dashboard/utils/fetching";

export * from "./getters";
export * from "./combiners";

export const copyToCommitHandler = (props) => {
  // Get the tracker and commit ID to the new commit
  let { copyToCommit, path: from_path } = props;
  let { trackerId, commitId } = copyToCommit;

  fetcher(`/api/tracker/${trackerId}/commit/${commitId}/node/`, "POST", { from_path }).then(({ payload }) => {
    console.log("copyToCommitHandler copied nodes", payload?.toJS(), "to commit", copyToCommit);
    location.reload();
  });
};
